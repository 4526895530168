import './App.scss';
import Rotas from './routes';

function App() {

  return (
    <Rotas />
  );
}

export default App;
