import { useEffect } from 'react';
import './App.scss';
import { useParams } from 'react-router-dom';

const Cardapio = () => {
    const { cdEmpresa, cdMesa } = useParams();
    useEffect(() => {
        setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_LINK}/${cdEmpresa}/${cdMesa ?? ""}`
        }, 3000);
    })

    return (
        <div className="App">
        <header className="App-header">
            <div>
            <img src={'/LogoCardapio.svg'} className="App-logo" alt="logo" />
            <h2>
                Cardápio
            </h2>
            </div>
            <code
            className="App-link"
            >
            escopo.app
            </code>
        </header>
        </div>
    );
}

export default Cardapio;
