import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cardapio from "./cardapio";


const Rotas = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/:cdEmpresa/:cdMesa?'} element={<Cardapio />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Rotas;